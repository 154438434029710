import React, { useMemo, useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import CardLink from "../../components/cards/card-link";
import { GatsbyImage } from "gatsby-plugin-image";
import CustomButtonText from "../../components/button/custom-button";
import ListOfCards from "../../components/cards/list-of-cards";
import ListDropdown from "../../components/lists/dropdown";
import { useI18next } from "gatsby-plugin-react-i18next";
import { getAlternateLang } from "../../utils/alternate-lang";
import PrivateRoute from "../../components/private-route";
import NoResult from "../../components/lists/noResult";
import { gtmPushUserInfo } from "../../services/auth";

export const query = graphql`
  query Products($language: String!) {
    productsEn: allStrapiEditorialContent(
      filter: {
        editorial_content_type: { Slug: { eq: "product" } }
        locale: { eq: "en" }
        # date: { ne: null }
      }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          strapiId
          Title
          Slug
          Description
          date(formatString: "MMMM DD, YYYY")
          therapeutical_area {
            Name
            Slug
            color
          }
          editorial_content_type {
            Name
            Slug
            color
          }
          Thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
        }
      }
    }
    productsEs: allStrapiEditorialContent(
      filter: {
        editorial_content_type: { Slug: { eq: "product" } }
        locale: { eq: "es-PA" }
      }
    ) {
      edges {
        node {
          id
          strapiId
          Title
          Slug
          Description
          date(formatString: "MMMM DD, YYYY")
          therapeutical_area {
            Name
            Slug
            color
          }
          editorial_content_type {
            Name
            Slug
            color
          }
          Thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(height: 223)
              }
            }
          }
        }
      }
    }
    therapeuticalAreas: allStrapiTherapeuticalArea {
      edges {
        node {
          Name
          Slug
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Products = ({ data }) => {
  const { t, language } = useI18next();
  const products =
    language === "en" ? data.productsEn.edges : data.productsEs.edges;
  const [activeSlugs, setActiveSlugs] = useState([]);
  const [seeHowMany, setSeeHowMany] = useState(3);

  const isFiltered = useMemo(() => {
    return activeSlugs.length > 0;
  }, [activeSlugs]);

  const filteredProducts = useMemo(() => {
    return activeSlugs.length > 0
      ? products.filter((n) =>
          activeSlugs.includes(n.node.therapeutical_area.Name)
        )
      : products;
  }, [activeSlugs, products]);

  const seo = {
    metaTitle: "Products",
    metaDescription: `All products`,
    linkAlternateHref: "/" + getAlternateLang(language) + "/products",
    linkHrefLang: getAlternateLang(language),
  };

  useEffect(() => {
    window.dataLayer?.push({
      event: "page_info",
      page_language: language,
      page_category: "Products",
      page_ta: "",
      page_type: "",
    });
    gtmPushUserInfo();
  }, [language]);

  const onChangeTherapeuticalAreas = (values) => {
    setSeeHowMany(values.length > 0 ? 6 : 3);
    setActiveSlugs(values);
  };

  const onLoadMore = () => {
    setSeeHowMany((previousValue) => previousValue + 3);
  };
  return (
    <Layout seo={seo}>
      <ListDropdown onChange={onChangeTherapeuticalAreas} />
      {filteredProducts.length > 0 ? (
        <ListOfCards
          title="Products"
          cards={filteredProducts.slice(0, seeHowMany).map((n) => (
            <CardLink
              title={n.node.Title}
              type="product"
              image={
                <GatsbyImage
                  image={
                    n.node.Thumbnail.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={n.node.Thumbnail.alternativeText}
                />
              }
              date={n.node.date}
              labelText={t("product")}
              labelColor={n.node.editorial_content_type.color}
              link={`/products/${n.node.Slug}`}
              therapeuticAreaLabel={n.node.therapeutical_area.Name}
              therapeuticAreaColor={n.node.therapeutical_area.color}
            />
          ))}
          button={
            filteredProducts.length > seeHowMany ? (
              <CustomButtonText
                text={t("loadMore")}
                onClick={onLoadMore}
                style={{
                  letterSpacing: "0.1em",
                }}
              />
            ) : (
              <></>
            )
          }
          backgroundColor="#FFFFFF"
          style={{ marginTop: 20, paddingLeft: 0, paddingRight: 0 }}
          disableCarousel={isFiltered}
        />
      ) : (
        <NoResult />
      )}
    </Layout>
  );
};

const ListProducts = ({ data }) => {
  return <PrivateRoute component={Products} data={data} />;
};

export default ListProducts;
